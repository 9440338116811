import axios, { AxiosResponse } from "axios";
import { authHeader } from "@/authHeader";
import store from "@/store";
import { AuthenticationService } from ".";
import { now } from "moment";

const baseURL = process.env.VUE_APP_BASE_URL;
let timeOutHandle: number | undefined;

const isTokenValid = () => {
  const token = JSON.parse(
    AuthenticationService.getTokenFromStorage() as string
  );
  const expires = new Date(token["expires"]);
  const now = new Date();
  return expires.getTime() > now.getTime();
};

export const Api = {
  baseURL,
  request: () => {
    const axiosInstance = axios.create({
      baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader()
      }
    });
    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        const status = error.response ? error.response.status : null;
        if (
          status === 401 &&
          location.pathname !== "/signin" &&
          location.pathname !== "/password/reset" &&
          location.pathname !== "/registration/confirm" &&
          AuthenticationService.getTokenFromStorage()
        ) {
          return store.dispatch("authentication/refreshToken").then(jwt => {
            error.config.headers["Authorization"] = authHeader();
            error.config.baseURL = baseURL;
            return axios.request(error.config);
          });
        }
        return Promise.reject(error);
      }
    );
    axiosInstance.interceptors.request.use(config => {
      if (
        !store.getters["authentication/keepMeSignedIn"] &&
        location.pathname !== "/signin" &&
        location.pathname !== "/password/reset" &&
        location.pathname !== "/registration/confirm"
      ) {
        if (isTokenValid()) {
          clearTimeout(timeOutHandle);
          timeOutHandle = setTimeout(() => {
            store.dispatch("authentication/logout");
          }, 20 * 60 * 1000);
        } else {
          store.dispatch("authentication/logout");
        }
      }
      return config;
    });
    return axiosInstance;
  },
  handleResponse: (response: AxiosResponse<any>) => {
    return response.data;
  }
};
