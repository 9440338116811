import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState, Task, TaskState } from "@/types";
import { TaskService } from "@/services";
import moment from "moment";
import { resolve } from "@/mixins";
import i18n from "@/plugins/i18n";

const state: TaskState = {
  tasks: [],
  dateFilteredTasks: [],
  loading: false
};
const actions: ActionTree<TaskState, RootState> = {
  async getAllTasks({ dispatch, commit, state }) {
    if (!state.loading) {
      commit("setLoading");
      try {
        const tasks: readonly Task[] = await TaskService.getAllTasks();
        commit("setTasks", tasks);
      } catch (err) {
        dispatch("alert/error", i18n.t("NOTIFICATION.REQUEST_FAILED"), {
          root: true
        });
      }
    }
  },
  async getDateFilteredTasks(
    { dispatch, commit, state },
    dateRange: { from: Date; to: Date }
  ) {
    if (state.tasks.length === 0) {
      await dispatch("getAllTasks");
    }
    const tasks = state.tasks;
    const dateFilteredTasks = tasks.filter(task => {
      return moment(task.creationDate).isBetween(
        moment(dateRange.from),
        moment(dateRange.to)
      );
    });
    commit("setDateFilteredTasks", dateFilteredTasks);
  }
};
const mutations: MutationTree<TaskState> = {
  setTasks(pstate, tasks: Task[]) {
    pstate.tasks = tasks;
    pstate.loading = false;
  },
  setDateFilteredTasks(pstate, tasks: Task[]) {
    pstate.dateFilteredTasks = tasks;
  },
  setLoading(pstate) {
    pstate.loading = true;
  }
};
const getters: GetterTree<TaskState, RootState> = {
  tasks(pstate) {
    return pstate["tasks"];
  },
  tasksFiltered(pstate) {
    return (filters: any) => {
      let filtered: readonly Task[] = pstate.dateFilteredTasks;
      if (Object.keys(filters).length > 0) {
        filtered = filtered.filter(task => {
          for (const column of Object.keys(filters)) {
            const filterValue = filters[column];
            if (filterValue !== "") {
              if (column === "realEstate.id") {
                if (filterValue.length > 0) {
                  const value = resolve<string>(column, task);
                  if (filterValue.indexOf(value) <= -1) {
                    return false;
                  }
                }
              } else if (column === "creationDate") {
                const value = moment(resolve<Date>(column, task)).format(
                  "DD.MM.YYYY"
                );
                if (value.indexOf(filterValue.toLowerCase()) <= -1) {
                  return false;
                }
              } else if (
                column === "taskType.name" ||
                column === "taskStatus.name"
              ) {
                const value = resolve<string>(column, task).toLowerCase();
                if (value !== filterValue.toLowerCase()) {
                  return false;
                }
              } else {
                const value = resolve<string>(column, task).toLowerCase();
                if (value.indexOf(filterValue.toLowerCase()) <= -1) {
                  return false;
                }
              }
            }
          }
          return true;
        });
      }
      return filtered;
    };
  },
  isLoading(pstate) {
    return pstate["loading"];
  }
};
export const task: Module<TaskState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
