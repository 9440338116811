import { Occurrence, Document } from "@/types";
import i18n from "@/plugins/i18n";
import moment from "moment";

const reportFields = {
  Number: i18n.t("OCCURRENCE.NUMBER"),
  RealEstate: i18n.t("OCCURRENCE.REAL_ESTATE"),
  BuildingName: i18n.t("OCCURRENCE.BUILDING"),
  Date: i18n.t("FILTER.RECORDED"),
  ResponsiblePersonName: i18n.t("OCCURRENCE.RESPONSIBLE_PERSON"),
  ResponsiblePersonEmail: i18n.t("GENERAL.EMAIL"),
  ResponsiblePersonMobilePhoneNumber: i18n.t("GENERAL.MOBILEPHONE"),
  OccurrenceType: i18n.t("OCCURRENCE.OCCURRENCE_TYPE"),
  TradeType: i18n.t("OCCURRENCE.TRADE"),
  ErrorCauseType: i18n.t("OCCURRENCE.ERROR_TYPE"),
  InstallationName: i18n.t("OCCURRENCE.INSTALLATION"),
  InstallationLevel: i18n.t("OCCURRENCE.LEVEL"),
  InstallationLocation: i18n.t("OCCURRENCE.LOCATION"),
  OccurrenceStatus: i18n.t("OCCURRENCE.STATUS"),
  Note: i18n.t("OCCURRENCE.DETAIL"),
  ErrorCauseDetails: i18n.t("OCCURRENCE.NOTE"),
  count_imgs: i18n.t("GENERAL.COUNT_IMAGES"),
  occurrence: i18n.t("OCCURRENCE.TITLESINGULAR")
};
const convertFileXHR = (url: string) => {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      if (xhr.status === 404) {
        // console.error("File not found. URL: " + url);
        resolve("File Missing");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
};
const pdfMake = () =>
  import(/* webpackChunkName: "pdfmake" */ "pdfmake-lite/build/pdfmake.min.js");
const pdfFonts = () =>
  import(/* webpackChunkName: "vfs_fonts" */ "pdfmake-lite/build/vfs_fonts.js");
export default {
  async getPdf(
    occurrence: Occurrence,
    documents: Document[],
    fileName: string
  ): Promise<void> {
    const pdfFontsMod = await pdfFonts();
    const pdfMakeMod = await pdfMake();
    pdfMakeMod.default.vfs = pdfFontsMod.default.pdfMake.vfs;
    const firstColWidth = "30%";
    const secondColWidth = "70%";
    const briefpapier = await convertFileXHR(
      require("@/assets/ics_briefpapier.jpg")
    );
    let a = {};
    const docImages: Array<{ base64: string }> = [];
    const listImages: Array<{ base64: string }> = [];
    for (const document of documents) {
      const data = (await convertFileXHR(document.path)) as string;
      if (data && data !== "File Missing") {
        listImages.push({
          base64: data
        });
      }
    }

    if (occurrence.tradeType.name && occurrence.tradeType.name === "") {
      a = {};
    } else {
      a = {
        columns: [
          {
            width: firstColWidth,
            text: reportFields.TradeType,
            bold: true,
            margin: [35, 0, 0, 10]
          },
          {
            width: secondColWidth,
            text: occurrence.tradeType.name,
            margin: [45, 0, 0, 10]
          }
        ]
      };
    }

    let installation = {
      name: {},
      level: {},
      location: {}
    };

    if (occurrence.installation.name && occurrence.installation.name !== "") {
      installation = {
        name: {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.InstallationName,
              bold: true,
              margin: [35, 0, 0, 10]
            },
            {
              width: secondColWidth,
              text: occurrence.installation.name,
              margin: [45, 0, 0, 10]
            }
          ]
        },
        level: {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.InstallationLevel,
              bold: true,
              margin: [35, 0, 0, 10]
            },
            {
              width: secondColWidth,
              text: occurrence.installation.ebene,
              margin: [45, 0, 0, 10]
            }
          ]
        },
        location: {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.InstallationLocation,
              bold: true,
              margin: [35, 0, 0, 10]
            },
            {
              width: secondColWidth,
              text: occurrence.installation.location,
              margin: [45, 0, 0, 10]
            }
          ]
        }
      };
    }

    let errorCauseDetails = {};
    if (occurrence.errorCauseDetails != null) {
      errorCauseDetails = {
        columns: [
          {
            width: firstColWidth,
            text: reportFields.ErrorCauseDetails,
            bold: true,
            margin: [35, 0, 0, 10]
          },
          {
            width: secondColWidth,
            text: occurrence.errorCauseDetails,
            margin: [45, 0, 0, 10]
          }
        ]
      };
    }
    const docDefinition = {
      background: {},
      content: [
        {
          text: reportFields.occurrence + " " + occurrence.number,
          style: "header",
          margin: [20, 70, 0, 30]
        },
        {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.RealEstate,
              bold: true,
              margin: [35, 0, 0, 10]
            },
            {
              width: secondColWidth,
              text: occurrence.realEstate,
              margin: [45, 0, 0, 10]
            }
          ]
        },
        {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.BuildingName,
              bold: true,
              margin: [35, 0, 0, 10]
            },
            {
              width: secondColWidth,
              text: occurrence.buildingName,
              margin: [45, 0, 0, 10]
            }
          ]
        },
        {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.Date,
              bold: true,
              margin: [35, 0, 0, 10]
            },
            {
              width: secondColWidth,
              text: moment(occurrence.date).format("DD.MM.YYYY"),
              margin: [45, 0, 0, 10]
            }
          ]
        },
        {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.OccurrenceType,
              bold: true,
              margin: [35, 0, 0, 10]
            },
            {
              width: secondColWidth,
              text: occurrence.occurrenceType.name,
              margin: [45, 0, 0, 10]
            }
          ]
        },
        {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.ErrorCauseType,
              bold: true,
              margin: [35, 0, 0, 10]
            },
            {
              width: secondColWidth,
              text: occurrence.errorCauseType.name,
              margin: [45, 0, 0, 10]
            }
          ]
        },
        installation.name,
        installation.level,
        installation.location,
        {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.OccurrenceStatus,
              bold: true,
              margin: [35, 0, 0, 10]
            },
            {
              width: secondColWidth,
              text: occurrence.occurrenceStatus.name,
              margin: [45, 0, 0, 10]
            }
          ]
        },
        a,
        {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.Note,
              bold: true,
              margin: [35, 0, 0, 10]
            },
            {
              width: secondColWidth,
              text: occurrence.description,
              margin: [45, 0, 0, 10]
            }
          ]
        },
        errorCauseDetails,
        {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.count_imgs,
              bold: true,
              margin: [35, 0, 0, 10]
            },
            {
              width: secondColWidth,
              text: listImages.length.toString(),
              margin: [45, 0, 0, 10]
            }
          ]
        },
        {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.ResponsiblePersonName,
              bold: true,
              margin: [35, 30, 0, 10]
            },
            {
              width: secondColWidth,
              text: `${occurrence.responsiblePerson.firstName} ${occurrence.responsiblePerson.lastName}`,
              margin: [45, 30, 0, 10]
            }
          ]
        },
        {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.ResponsiblePersonMobilePhoneNumber,
              bold: true,
              margin: [35, 0, 0, 10]
            },
            {
              width: secondColWidth,
              text: occurrence.responsiblePerson.mobilePhoneNumber,
              margin: [45, 0, 0, 10]
            }
          ]
        },
        {
          columns: [
            {
              width: firstColWidth,
              text: reportFields.ResponsiblePersonEmail,
              bold: true,
              margin: [35, 0, 0, 30]
            },
            {
              width: secondColWidth,
              text: occurrence.responsiblePerson.email,
              margin: [45, 0, 0, 30]
            }
          ]
        }
      ],
      pageSize: "A4",
      defaultStyle: {
        fontSize: 14
      },
      styles: {
        header: {
          fontSize: 23
        }
      }
    };

    const tmp = listImages.slice();
    if (tmp.length > 0) {
      docDefinition.content.push({
        text: "",
        width: 595,
        pageBreak: "before"
      });
    }
    let first = true;
    while (tmp.length) {
      const margin = first ? [35, 70, 0, 10] : [35, 10, 0, 10];
      first = false;
      const x = tmp.splice(0, 4);
      const cols = [];
      for (const value of x) {
        cols.push({
          image: value.base64,
          width: 100,
          margin
        });
        cols.push({
          text: "",
          width: 10
        });
      }
      docDefinition.content.push({
        columns: cols
      });
    }
    for (const value of listImages) {
      docDefinition.content.push({
        text: "",
        width: 595,
        pageBreak: "before"
      });
      docImages.push({
        base64: value.base64
      });
    }

    docDefinition.background = (currentPage: any) => {
      if (currentPage <= 2) {
        return {
          image: briefpapier,
          height: 841.4,
          width: 595
        };
      } else {
        const image = docImages[currentPage - 3];
        return {
          image: image.base64,
          width: 595
        };
      }
    };
    pdfMakeMod.createPdf(docDefinition).download(fileName + ".pdf");
  }
};
