import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState, RealEstate, RealEstateState } from "@/types";
import realEstateService from "@/services/realEstateService";
import i18n from "@/plugins/i18n";

const state: RealEstateState = {
  realEstates: [],
  loading: false
};
const actions: ActionTree<RealEstateState, RootState> = {
  async getAllRealEstates({ dispatch, commit, state }) {
    if (!state.loading) {
      commit("setLoading");
      try {
        const realEstates: RealEstate[] = await realEstateService.getAllRealEstates();
        commit("setRealEstates", realEstates);
      } catch (err) {
        dispatch("alert/error", i18n.t("NOTIFICATION.REQUEST_FAILED"), {
          root: true
        });
      }
    }
  }
};
const mutations: MutationTree<RealEstateState> = {
  setRealEstates(pstate, realEstates: RealEstate[]) {
    pstate.realEstates = realEstates;
    pstate.loading = false;
  },
  setLoading(pstate) {
    pstate.loading = true;
  }
};
const getters: GetterTree<RealEstateState, RootState> = {
  realEstates(pstate) {
    return pstate["realEstates"];
  },
  isLoading(pstate) {
    return pstate["loading"];
  }
};
export const realEstate: Module<RealEstateState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
