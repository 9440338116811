
import Vue from "vue";
import ConfirmationDialogue from "@/components/ConfirmationDialogue.vue";
import PasswordChangeModal from "@/components/PasswordChangeModal.vue";
import { AuthenticationService } from "./services";

const Component = Vue.extend({
  name: "App",
  methods: {
    acceptCookies(): void {
      window.localStorage.setItem("useCookiesConfirmed", "true");
      this.cookiesNotConfirmed = false;
    }
  },
  created(): void {
    this.$store.dispatch("authentication/getAuthenticatedUser");
  },
  computed: {
    alert(): string {
      return this.$store.state.alert;
    },
    notification: {
      get(): boolean {
        return !!this.$store.state.alert.message;
      },
      set(): void {
        this.$store.dispatch("alert/clear");
      }
    },
    loggedIn(): boolean {
      return this.$store.state.authentication.status.loggedIn;
    }
  },
  watch: {
    $route(to, from) {
      // clear alert on location change
      // this.$store.dispatch('alert/clear');
    }
  },
  data() {
    const cookiesNotConfirmed = !window.localStorage.getItem(
      "useCookiesConfirmed"
    );
    return {
      cookiesNotConfirmed,
      appTitle: "ICS FS-Helpdesk",
      layout: "div"
    };
  },
  components: {
    confirm: ConfirmationDialogue,
    passwordChange: PasswordChangeModal
  },
  mounted() {
    Vue.prototype.$confirm = (this.$refs.confirm as any).open;
    Vue.prototype.$changePassword = (this.$refs.password as any).open;
  }
});
export default Component;
