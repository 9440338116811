import Vue from "vue";
import Router from "vue-router";
import { AuthenticationService } from "./services";

const routerOptions = [
  { path: "/", component: "Occurrences" },
  { path: "/ereignis", component: "Occurrences" },
  { path: "/report", component: "Reports" },
  { path: "/users", component: "Users" },
  { path: "/signin", component: "Signin" },
  { path: "/password/reset", component: "ResetPassword" },
  { path: "/registration/confirm", component: "ConfirmAccount" },
  { path: "/debug", component: "Debug" }
];

const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import(`@/views/${route.component}.vue`)
  };
});

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes
});
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/signin", "/signup", "/password/reset", "/registration/confirm"];
  const userPages = ["/", "/ereignis", "/report"];

  const authRequired = !publicPages.includes(to.path);
  const needsAdminRole = authRequired && !userPages.includes(to.path);

  const loggedIn = AuthenticationService.getTokenFromStorage();
  if (authRequired && !loggedIn) {
    return next("/signin");
  } else if (authRequired && needsAdminRole) {
    if (!loggedIn) {
      return next("/signin");
    }
    const user = JSON.parse(loggedIn);
    // user.roles = JSON.parse(user.roles);
    if (!user.roles.includes("Administrator")) {
      return next("/signin");
    }
  }
  next();
});
export default router;
