import { Occurrenceservice } from "@/services/";
import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { Occurrence, OccurrenceState, RootState } from "@/types";
import { resolve } from "@/mixins";
import moment from "moment";
import i18n from "@/plugins/i18n";

const state: OccurrenceState = {
  occurrences: [],
  dateFilteredOccurrences: [],
  loading: false
};
const actions: ActionTree<OccurrenceState, RootState> = {
  async getAllOccurrences({ dispatch, commit, state }) {
    if (!state.loading) {
      commit("setLoading");
      try {
        const occurrences: readonly Occurrence[] = Object.freeze(
          await Occurrenceservice.getAllOccurrences()
        );
        commit("setOccurrences", occurrences);
      } catch (err) {
        dispatch("alert/error", i18n.t("NOTIFICATION.REQUEST_FAILED"), {
          root: true
        });
      }
    }
  },
  async getDateFilteredOccurrences(
    { dispatch, commit, state },
    dateRange: { from: Date; to: Date }
  ) {
    if (state.occurrences.length === 0) {
      await dispatch("getAllOccurrences");
    }
    const occurrences = state.occurrences;
    const dateFilteredOccurrences = occurrences.filter(occurrence => {
      return moment(occurrence.date).isBetween(
        moment(dateRange.from),
        moment(dateRange.to)
      );
    });
    commit("setDateFilteredOccurrences", dateFilteredOccurrences);
  },
  async addOccurrence({ dispatch, commit, state }, occurrence: any) {
    try {
      await Occurrenceservice.addOccurrence(occurrence);
      // Add (returned) occurrence or reload occurrences
    } catch (err) {
      dispatch("alert/error", i18n.t("NOTIFICATION.REQUEST_FAILED"), {
        root: true
      });
    }
  }
};
const mutations: MutationTree<OccurrenceState> = {
  setOccurrences(pstate, occurrences: Occurrence[]) {
    pstate.occurrences = Object.freeze(occurrences);
    pstate.loading = false;
  },
  setDateFilteredOccurrences(pstate, occurrences: Occurrence[]) {
    pstate.dateFilteredOccurrences = Object.freeze(occurrences);
  },
  setLoading(pstate) {
    pstate.loading = true;
  }
};
const getters: GetterTree<OccurrenceState, RootState> = {
  occurrences(pstate) {
    return pstate["occurrences"];
  },
  occurrencesFiltered(pstate) {
    return (filters: any) => {
      let filtered: readonly Occurrence[] = pstate.dateFilteredOccurrences;
      if (Object.keys(filters).length > 0) {
        filtered = filtered.filter(occurrence => {
          for (const column of Object.keys(filters)) {
            const filterValue = filters[column];
            if (filterValue !== "") {
              if (column === "realEstateId") {
                if (filterValue.length > 0) {
                  const value = resolve<string>(column, occurrence);
                  if (filterValue.indexOf(value) <= -1) {
                    return false;
                  }
                }
              } else if (column === "date") {
                const value = moment(resolve<Date>(column, occurrence)).format(
                  "DD.MM.YYYY"
                );
                if (value.indexOf(filterValue.toLowerCase()) <= -1) {
                  return false;
                }
              } else if (
                column === "occurrenceType.name" ||
                column === "occurrenceStatus.name"
              ) {
                const value = resolve<string>(column, occurrence).toLowerCase();
                if (value !== filterValue.toLowerCase()) {
                  return false;
                }
              } else {
                const value = resolve<string>(column, occurrence).toLowerCase();
                if (value.indexOf(filterValue.toLowerCase()) <= -1) {
                  return false;
                }
              }
            }
          }
          return true;
        });
      }
      return filtered;
    };
  },
  isLoading(pstate) {
    return pstate["loading"];
  }
};
export const occurrence: Module<OccurrenceState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
