import { Api } from "@/services/api";
import { User } from "@/types";
import jsSHA from "jssha";

const hashPassword = (password: string): string => {
  const hashObj = new jsSHA("SHA-512", "TEXT", { numRounds: 1 });
  hashObj.update(password);
  return hashObj.getHash("HEX");
};

export default {
  addTokenToStorage(jwt: any) {
    const keepMeSignedIn = JSON.parse(localStorage.getItem(
      "keepMeSignedIn"
    ) as string);
    if (keepMeSignedIn) {
      localStorage.setItem("user", JSON.stringify(jwt));
    } else {
      localStorage.setItem("user", JSON.stringify(jwt));
    }
  },
  getTokenFromStorage() {
    const keepMeSignedIn = JSON.parse(localStorage.getItem(
      "keepMeSignedIn"
    ) as string);
    let token;
    if (keepMeSignedIn) {
      token = localStorage.getItem("user");
    } else {
      token = localStorage.getItem("user");
    }
    return token;
  },
  removeTokenFromStorage() {
    const keepMeSignedIn = JSON.parse(localStorage.getItem(
      "keepMeSignedIn"
    ) as string);
    if (keepMeSignedIn) {
      localStorage.removeItem("user");
    } else {
      localStorage.removeItem("user");
    }
  },
  async register(data: {
    username: string;
    email: string;
    password: string;
  }): Promise<User> {
    let response;
    try {
      response = await Api.request().post("/register", data);
    } catch (err) {
      throw err;
    }
    return response.data;
  },
  async requestNewPassword(email: string): Promise<void> {
    try {
      await Api.request().put("/api/accounts/me/password/request", {
        Email: email
      });
    } catch (err) {
      throw err;
    }
  },
  async resetPassword(data: {
    email: string;
    newPassword: string;
    confirmPassword: string;
    token: string;
  }): Promise<void> {
    data.newPassword = hashPassword(data.newPassword);
    data.confirmPassword = hashPassword(data.confirmPassword);
    try {
      await Api.request().put("/api/accounts/me/password/reset", data);
    } catch (err) {
      throw err;
    }
  },
  async confirmAccount(data: {
    email: string;
    newPassword: string;
    confirmPassword: string;
    token: string;
  }): Promise<void> {
    data.newPassword = hashPassword(data.newPassword);
    data.confirmPassword = hashPassword(data.confirmPassword);
    try {
      await Api.request().put("/api/accounts/me/password/confirm", data);
    } catch (err) {
      throw err;
    }
  },
  async changePassword(data: {
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
  }): Promise<void> {
    data.oldPassword = hashPassword(data.oldPassword);
    data.newPassword = hashPassword(data.newPassword);
    data.newPasswordRepeat = hashPassword(data.newPasswordRepeat);
    try {
      await Api.request().put("/api/accounts/me/password", data);
    } catch (err) {
      throw err;
    }
  },
  async login(data: {
    username: string;
    password: string;
    keepMeSignedIn: boolean;
  }): Promise<any> {
    data.password = hashPassword(data.password);
    const uriEncodedData =
      "grant_type=password&username=" +
      encodeURIComponent(data.username) +
      "&password=" +
      encodeURIComponent(data.password);
    let response;
    let jwt: any;
    try {
      response = await Api.request().post("/api/auth/login", uriEncodedData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });
      jwt = Api.handleResponse(response);
    } catch (err) {
      throw err;
    }
    localStorage.setItem("keepMeSignedIn", JSON.stringify(data.keepMeSignedIn));
    this.addTokenToStorage(jwt);
    return jwt;
  },
  async refreshAccessToken(): Promise<any> {
    const userItem = this.getTokenFromStorage();
    if (!userItem) {
      return { success: false, token: "" };
    }
    const user = JSON.parse(userItem);
    if (user && user.refresh_token) {
      const uriEncodedData =
        "grant_type=refresh_token&refresh_token=" + user.refresh_token;
      let response;
      let jwt: any;
      try {
        response = await Api.request().post("/api/auth/login", uriEncodedData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
          }
        });
        jwt = Api.handleResponse(response);
      } catch (err) {
        throw err;
      }
      this.addTokenToStorage(jwt);
      return jwt;
    }
    return { success: false, token: "" };
  },
  async getAuthenticatedUser(): Promise<User> {
    let response;
    let data;
    try {
      response = await Api.request().get("/api/accounts/me");
      data = Api.handleResponse(response);
    } catch (err) {
      throw err;
    }
    // const user: User = {
    //   id: data.ID,
    //   icsId: data.ICS_ID || "",
    //   email: data.EMail,
    //   firstName: data.FirstName,
    //   lastName: data.LastName,
    //   roles: data.Roles,
    //   userName: data.UserName,
    //   active: data.Active || false,
    //   activatedAt: data.ActivatedAt || null,
    //   createdAt: data.CreatedAt || null,
    //   lastLogin: data.LastLogin || null
    // };
    // console.log("usredata api:", data);
    return data;
  },
  logout() {
    // remove user from local storage to log user out
    this.removeTokenFromStorage();
    if (
      location.pathname !== "/signin" &&
      location.pathname !== "/password/reset" &&
      location.pathname !== "/registration/confirm"
    ) {
      location.reload();
    }
  }
};
