import { Api } from "@/services/api";
import { RealEstate, PostalCode } from "@/types";

const getPostalCode = ({
  Id = "",
  location = "",
  postal_code = "",
  ref_country = ""
} = {}): PostalCode => {
  return {
    id: Id,
    location,
    postal_code,
    ref_country
  };
};
export default {
  async getAllRealEstates(): Promise<RealEstate[]> {
    let response;
    let data;
    try {
      response = await Api.request().get("/api/realestates/GetAll/true");
      data = Api.handleResponse(response);
    } catch (err) {
      throw err;
    }
    const realestates: RealEstate[] = data.map((elem: any) => {
      const postalCode = getPostalCode(elem._postalCode);      
      elem.fullName = elem.name + ", " + elem.postalCode,
      elem._postalCode = postalCode;
      return elem;
    });
    return realestates;
  }
};
