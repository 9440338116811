import router from "@/router";
import { Module } from "vuex";
import { AuthenticationState, RootState, User } from "@/types";
import i18n from "@/plugins/i18n";
import { AuthenticationService } from "@/services";

const keepMeSignedIn = JSON.parse(localStorage.getItem(
  "keepMeSignedIn"
) as string);
let userAuthItem;
if (keepMeSignedIn) {
  userAuthItem = localStorage.getItem("user");
} else {
  userAuthItem = localStorage.getItem("user");
}
const userAuth = userAuthItem ? JSON.parse(userAuthItem) : undefined;
const user = {
  userName: "",
  firstName: "",
  lastName: "",
  id: -1,
  icsId: "",
  email: "",
  roles: [],
  active: false,
  activatedAt: new Date(),
  createdAt: new Date(),
  lastLogin: new Date()
};
const initialState: AuthenticationState = userAuth
  ? { status: { loggedIn: true, requestingRefresh: false }, userAuth, user }
  : { status: { loggedIn: false, requestingRefresh: false }, userAuth, user };

export const authentication: Module<AuthenticationState, RootState> = {
  namespaced: true,
  state: initialState,
  actions: {
    async changePassword(
      { dispatch },
      data: {
        oldPassword: string;
        newPassword: string;
        newPasswordRepeat: string;
      }
    ): Promise<boolean> {
      try {
        await AuthenticationService.changePassword(data);
        dispatch(
          "alert/success",
          i18n.t("NOTIFICATION.PASSWORD_CHANGE_SUCCESS"),
          { root: true }
        );
        return true;
      } catch (err) {
        dispatch("alert/error", i18n.t("NOTIFICATION.PASSWORD_CHANGE_FAIL"), {
          root: true
        });
        return false;
      }
    },
    async confirmAccount(
      { dispatch },
      data: {
        email: string;
        newPassword: string;
        confirmPassword: string;
        token: string;
      }
    ): Promise<void> {
      try {
        await AuthenticationService.confirmAccount(data);
        dispatch(
          "alert/success",
          i18n.t("NOTIFICATION.PASSWORD_RESET_SUCCESS"),
          { root: true }
        );
        router.push("/");
      } catch (err) {
        dispatch("alert/error", i18n.t("NOTIFICATION.PASSWORD_RESET_FAIL"), {
          root: true
        });
      }
    },
    async resetPassword(
      { dispatch },
      data: {
        email: string;
        newPassword: string;
        confirmPassword: string;
        token: string;
      }
    ): Promise<void> {
      try {
        await AuthenticationService.resetPassword(data);
        dispatch(
          "alert/success",
          i18n.t("NOTIFICATION.PASSWORD_RESET_SUCCESS"),
          { root: true }
        );
        router.push("/");
      } catch (err) {
        dispatch("alert/error", i18n.t("NOTIFICATION.PASSWORD_RESET_FAIL"), {
          root: true
        });
      }
    },
    async requestNewPassword({ dispatch }, email: string): Promise<boolean> {
      try {
        await AuthenticationService.requestNewPassword(email);
        return true;
      } catch (err) {
        dispatch("alert/error", i18n.t("NOTIFICATION.PASSWORD_REQUEST_FAIL"), {
          root: true
        });
        return false;
      }
    },
    async login(
      { dispatch, commit },
      data: { username: string; password: string; keepMeSignedIn: boolean }
    ) {
      try {
        const userAuth = await AuthenticationService.login(data);
        dispatch("alert/success", i18n.t("NOTIFICATION.LOGIN_SUCCESS"), {
          root: true
        });
        dispatch("getAuthenticatedUser");
        commit("loginSuccess", userAuth);
        router.push("/");
      } catch (error) {
        commit("loginFailure", error);
        dispatch("alert/error", i18n.t("NOTIFICATION.LOGIN_FAIL"), {
          root: true
        });
      }
    },
    async refreshToken({ dispatch, commit, state }) {
      if (!state.status.requestingRefresh) {
        try {
          commit("refreshRequest", AuthenticationService.refreshAccessToken());
          await state.refreshPromise;
          commit("loginSuccess", userAuth);
        } catch (error) {
          commit("loginFailure", error);
        }
      } else if (state.refreshPromise) {
        return state.refreshPromise;
      }
    },
    logout({ dispatch, commit }) {
      AuthenticationService.logout();
      commit("logout");
    },
    async register(
      { commit },
      payload: { username: string; email: string; password: string }
    ) {
      const user: User = await AuthenticationService.register(payload);
      commit("setUser", user);
    },
    async getAuthenticatedUser({ commit }) {
      try {
        const user: User = await AuthenticationService.getAuthenticatedUser();
        commit("setUser", user);
      } catch (err) {
        return;
      }
    }
  },
  mutations: {
    refreshRequest(state, refreshPromise) {
      state.status = { ...state.status, requestingRefresh: true };
      state.refreshPromise = refreshPromise;
    },
    loginSuccess(state, userAuth) {
      state.status = { requestingRefresh: false, loggedIn: true };
      state.userAuth = userAuth;
    },
    loginFailure(state) {
      state.status = { ...state.status };
      state.userAuth = undefined;
    },
    logout(state) {
      state.status = { loggedIn: false, requestingRefresh: false };
      state.userAuth = undefined;
    },
    setUser(state, user: User) {
      state.user = user;
    }
  },
  getters: {
    user(state) {
      return state["user"];
    },
    keepMeSignedIn() {
      return keepMeSignedIn;
    }
  }
};
