import { Api } from "@/services/api";

export default {
  async getRoles(): Promise<string[]> {
    let response;
    let data;
    try {
      response = await Api.request().get("/api/roles/get");
      data = Api.handleResponse(response).map((x: string) => { return { 'name': x } });
    } catch (err) {
      throw err;
    }
    return data;
  }
};
