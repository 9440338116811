import { UserService } from "@/services/";
import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { User, UserState, RootState } from "@/types";
import { resolve } from "@/mixins";
import i18n from "@/plugins/i18n";

const state: UserState = {
  users: [],
  loading: false
};
const actions: ActionTree<UserState, RootState> = {
  async getAllUsers({ dispatch, commit, state }): Promise<void> {
    if (!state.loading) {
      commit("setLoading");
      try {
        const users: User[] = await UserService.getAllUsers();
        commit("setUsers", users);
      } catch (err) {
        dispatch("alert/error", i18n.t("NOTIFICATION.REQUEST_FAILED"), {
          root: true
        });
      }
    }
  },
  async removeUser({ dispatch }, userId: number): Promise<void> {
    try {
      await UserService.removeUser(userId);
      dispatch("alert/success", i18n.t("NOTIFICATION.USER_DELETE_SUCCESS"), {
        root: true
      });
      dispatch("getAllUsers");
    } catch (err) {
      dispatch("alert/error", i18n.t("NOTIFICATION.REQUEST_FAILED"), {
        root: true
      });
    }
  },
  async addUser({ dispatch }, user: User): Promise<void> {
    try {
      await UserService.addUser(user);
      dispatch("alert/success", i18n.t("NOTIFICATION.USER_ADD_SUCCESS"), {
        root: true
      });
      dispatch("getAllUsers");
    } catch (err) {
      dispatch("alert/error", i18n.t("NOTIFICATION.REQUEST_FAILED"), {
        root: true
      });
    }
  },
  async updateUser({ dispatch }, user: User): Promise<void> {
    try {
      await UserService.updateUser(user);
      dispatch("alert/success", i18n.t("NOTIFICATION.USER_UPDATE_SUCCESS"), {
        root: true
      });
      dispatch("getAllUsers");
    } catch (err) {
      dispatch("alert/error", i18n.t("NOTIFICATION.REQUEST_FAILED"), {
        root: true
      });
    }
  }
};
const mutations: MutationTree<UserState> = {
  setLoading(state) {
    state.loading = true;
  },
  setUsers(state, users: User[]) {
    state.loading = false;
    state.users = users;
  }
};
const getters: GetterTree<UserState, RootState> = {
  users(state) {
    return state["users"];
  },
  usersFiltered(state) {
    return (filters: any) => {
      let filtered: User[] = state.users;
      if (Object.keys(filters).length > 0) {
        filtered = filtered.filter(user => {
          for (const column of Object.keys(filters)) {
            const filterValue = filters[column];
            if (filterValue !== "" && column === "id") {
              const value = resolve<number>(column, user);
              if (value.toString().indexOf(filterValue.toLowerCase()) <= -1) {
                return false;
              }
            } else if (filterValue !== "") {
              let value = resolve<string>(column, user) || "";
              value = value.toLowerCase();
              if (value.indexOf(filterValue.toLowerCase()) <= -1) {
                return false;
              }
            }
          }
          return true;
        });
      }
      return filtered;
    };
  },
  isLoading(state) {
    return state["loading"];
  }
};
export const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
