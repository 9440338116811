import Vue from "vue";
import moment from "moment";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import i18n from "./plugins/i18n";
import router from "./router";
import store from "./store/";
import "./registerServiceWorker";
import "./less/main.less";
Vue.config.productionTip = false;

Vue.filter("formatDate", (value: string) => {
  if (value) {
    return moment(value).format("DD.MM.YYYY");
  }
});
Vue.mixin({
  data() {
    return {
      formRules: {
        email: (value: string) => {
          // tslint:disable-next-line:max-line-length
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || i18n.t("VALIDATION.EMAIL");
        },
        required: (value: any) => !!value || i18n.t("VALIDATION.REQUIRED"),
        username_required: (value: any) =>
          !!value || i18n.t("VALIDATION.USERNAME_REQUIRED"),
        password_required: (value: any) =>
          !!value || i18n.t("VALIDATION.PASSWORD_REQUIRED")
      }
    };
  }
});

new Vue({
  router,
  store,
  vuetify,

  i18n,
  render: h => h(App)
}).$mount("#app");
