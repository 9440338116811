
import Vue from "vue";
import BaseDialog from "@/components/BaseDialog.vue";

export default Vue.extend({
  data() {
    return {
      dialog: false,
      oldPassword: "",
      password: "",
      passwordConfirm: ""
    };
  },
  computed: {
    comparePasswords(): true | string {
      if (!this.password) {
        return true;
      }

      return this.password === this.passwordConfirm
        ? true
        : "Passwörter stimmen nicht überein";
    },
    minlengthPassword(): true | string {
      if (!this.password) {
        return true;
      }
      return this.password.length >= 5 ? true : "Password zu kurz";
    }
  },
  methods: {
    async changePassword() {
      if (
        !(this.$refs.form as any).validate() ||
        this.password !== this.passwordConfirm ||
        !this.oldPassword
      ) {
        return;
      }
      if (
        await this.$store.dispatch("authentication/changePassword", {
          newPassword: this.password,
          newPasswordRepeat: this.passwordConfirm,
          oldPassword: this.oldPassword
        })
      ) {
        this.dialog = false;
      }
    },
    open() {
      this.oldPassword = "";
      this.password = "";
      this.passwordConfirm = "";
      this.dialog = true;
      this.$nextTick(function(this: any) {
        (this.$refs.form as any).reset();
      });
    }
  },
  components: {
    "base-dialog": BaseDialog
  }
});
