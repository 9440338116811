import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const locale = window.localStorage.getItem("lang") || "de";

const messages = {
  en: require("./../i18n/locale-en.json"),
  de: require("./../i18n/locale-de.json")
};

const i18n = new VueI18n({
  locale,
  messages
});

export default i18n;
