import { AuthenticationService } from "./services";

export function authHeader(): string {
  // return authorization header with jwt token
  const userItem = AuthenticationService.getTokenFromStorage();
  if (!userItem) {
    return "";
  }
  const user = JSON.parse(userItem);
  if (user && user.access_token) {
    return "bearer " + user.access_token; // TODO: Add tokenExtractor for reusability
  } else {
    return "";
  }
}
