import { DocumentService } from "@/services";
import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { Document, DocumentState, RootState } from "@/types";

import Vue from "vue";
import i18n from "@/plugins/i18n";

const state: DocumentState = {
  documents: {},
  loading: false
};
const actions: ActionTree<DocumentState, RootState> = {
  async getAllDocuments(
    { dispatch, commit, state },
    occurrenceId: string
  ): Promise<void> {
    if (!state.loading) {
      commit("setLoading");
      try {
        const documents: Document[] = await DocumentService.getAllDocuments(
          occurrenceId
        );
        commit("setDocuments", { documents, occurrenceId });
      } catch (err) {
        dispatch("alert/error", i18n.t("NOTIFICATION.REQUEST_FAILED"), {
          root: true
        });
      }
    }
  }
};
const mutations: MutationTree<DocumentState> = {
  setDocuments(state, { documents, occurrenceId }) {
    Vue.set(state.documents, occurrenceId, documents);
    state.loading = false;
  },
  setLoading(state) {
    state.loading = true;
  }
};
const getters: GetterTree<DocumentState, RootState> = {
  documents(state) {
    return (occurrenceId: string) => {
      return state["documents"][occurrenceId] || [];
    };
  },
  isLoading(state) {
    return state["loading"];
  }
};
export const document: Module<DocumentState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
