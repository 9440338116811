
import Vue from "vue";
export default Vue.extend({
  props: { title: String, value: Boolean, _width: String },
  computed: {
    width() {
      return this._width || 900;
    },
    dialog: {
      get(this: any): boolean {
        return this.value;
      },
      set(this: any, value: boolean): void {
        this.$emit("input", value);
      }
    }
  }
});
