import Vue from "vue";
import { Module } from "vuex";
import { RootState, AlertState } from "@/types";
export const alert: Module<AlertState, RootState> = {
  namespaced: true,
  state: {
    type: undefined,
    message: undefined
  },
  actions: {
    success({ commit }, message) {
      commit("success", message);
    },
    error({ commit }, message) {
      commit("error", message);
    },
    info({ commit }, message) {
      commit("info", message);
    },
    clear({ commit }) {
      commit("clear");
    }
  },
  mutations: {
    success(state, message) {
      Vue.set(state, "type", "success");
      Vue.set(state, "message", message);
    },
    error(state, message) {
      Vue.set(state, "type", "error");
      Vue.set(state, "message", message);
    },
    info(state, message) {
      state.type = "info";
      state.message = message;
    },
    clear(state) {
      state.type = undefined;
      state.message = undefined;
    }
  }
};
