import { Api } from "@/services/api";
import { LogMessage } from "@/types";
export default {
  async postLogMessage(log: LogMessage): Promise<void> {
    let response;
    try {
      response = await Api.request().post("/api/log", {
        Action: log.action,
        Message: log.message,
        Level: log.level
      });
      Api.handleResponse(response);
    } catch (err) {
      throw err;
    }
  }
};
