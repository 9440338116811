import { RoleService } from "@/services/";
import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { RoleState, RootState } from "@/types";
import { resolve } from "@/mixins";
import i18n from "@/plugins/i18n";

const state: RoleState = {
  roles: [],
  loading: false
};
const actions: ActionTree<RoleState, RootState> = {
  async getRoles({ dispatch, commit, state }): Promise<void> {
    if (!state.loading) {
      commit("setLoading");
      try {
        const roles: string[] = await RoleService.getRoles();
        commit("setRoles", roles);
      } catch (err) {
        dispatch("alert/error", i18n.t("NOTIFICATION.REQUEST_FAILED"), {
          root: true
        });
      }
    }
  }
};
const mutations: MutationTree<RoleState> = {
  setLoading(state) {
    state.loading = true;
  },
  setRoles(state, roles: string[]) {
    state.loading = false;
    state.roles = roles;
  }
};
const getters: GetterTree<RoleState, RootState> = {
  roles(state) {
    return state["roles"];
  },
  isLoading(state) {
    return state["loading"];
  }
};
export const role: Module<RoleState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
