export function resolve<T>(
  path: string | string[],
  obj: any,
  separator = "."
): T | string {
  if (!path) {
    return "";
  }
  const properties = Array.isArray(path) ? path : path.split(separator);
  const erg = properties.reduce((prev, curr) => {
    return prev && prev[curr] ? prev[curr] : "";
  }, obj);
  // console.log(erg);
  return erg;
}
