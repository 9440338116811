import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import i18n from "../plugins/i18n";
import { RootState } from "@/types";
import { user } from "./modules/user.store";
import { authentication } from "./modules/authentication.store";
import { alert } from "./modules/alert.store";
import { occurrence } from "./modules/occurrence.store";
import { realEstate } from "./modules/realEstate.store";
import { document } from "./modules/document.store";
import { task } from "./modules/task.store";
import { role } from "./modules/role.store";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    user,
    alert,
    authentication,
    occurrence,
    realEstate,
    document,
    task,
    role
  },
  state: {
    locales: ["en", "de"],
    locale: i18n.locale
  },
  mutations: {
    SET_LANG(state, locale) {
      if (state.locales.indexOf(locale) !== -1) {
        state.locale = locale;
        i18n.locale = locale;
        window.localStorage.setItem("lang", locale);
      }
    }
  },
  actions: {
    setLang({ commit }, payload) {
      commit("SET_LANG", payload);
    }
  }
};

export default new Vuex.Store<RootState>(store);
