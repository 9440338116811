import { Api } from "@/services/api";
import {
  ResponsiblePerson,
  Task,
  TaskStatus,
  RealEstate,
  TaskDocument
} from "@/types";

const getResponsiblePerson = ({
  ID = "",
  LastName = "",
  FirstName = "",
  MobilePhoneNumber = "",
  Email = "",
  IsContactPerson = false
} = {}): ResponsiblePerson => {
  return {
    id: ID,
    lastName: LastName,
    firstName: FirstName,
    mobilePhoneNumber: MobilePhoneNumber,
    email: Email,
    isContactPerson: IsContactPerson
  };
};
const getRealEstate = ({ ID = "", Street = "" } = {}): RealEstate => {
  return {
    id: ID,
    aks: "",
    city: "",
    creationDate: new Date(),
    modificationDate: new Date(),
    name: "",
    fullName: "",
    postalCode: "",
    realEstateNumber: "",
    street: Street,
    _postalCode: {
      id: "",
      location: "",
      postal_code: "",
      ref_country: ""
    }
  };
};
const getDocument = ({
  Path = "",
  Title = "",
  Type = { name: "" }
} = {}): TaskDocument => {
  if (!Type.name) {
    Type.name = "";
  }
  const isHttpOrigin = location.protocol === "http:";
  const protocol = isHttpOrigin ? "http" : "https";
 
  return {
    path: `${protocol}://helpdesk.ics-fs.at${Path}`,
    title: Title,
    type: Type
  };
};
const getTaskStatus = ({ ID = "", Name = "" } = {}): TaskStatus => {
  return {
    id: ID,
    name: Name
  };
};
export default {
  async getAllTasks(): Promise<Task[]> {
    let response;
    let data;
    try {
      response = await Api.request().get("/api/tasks/GetAll");
      data = Api.handleResponse(response);
    } catch (err) {
      throw err;
    }
    const tasks: Task[] = data.map((elem: any, idx: number) => {
      // const taskStatus = getTaskStatus(elem.TaskStatus);
      // const personCreated = getResponsiblePerson(elem.ResponsiblePerson);
      // const realEstate = getRealEstate(elem.RealEstate);
      // const document = getDocument(elem.Document);
      // const task: Task = {
      //   id: idx,
      //   realEstate,
      //   modificationDate: new Date(elem.ModificationDate),
      //   creationDate: new Date(elem.CreationDate),
      //   taskStatus,
      //   personCreated,
      //   document
      // };
      elem.modificationDate = new Date(elem.modificationDate);
      elem.creationDate = new Date(elem.creationDate);
      return elem;
    });
    
    return tasks;
  }
};
