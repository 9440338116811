import { Api } from "@/services/api";
import {
  Occurrence,
  Building,
  OccurrenceType,
  ErrorCauseType,
  TradeType,
  OccurrenceStatus,
  Installation,
  ResponsiblePerson
} from "@/types";
import i18n from "@/plugins/i18n";
import { saveAs } from "file-saver";
import moment from "moment";

const getBuilding = ({ id = "", name = "", name2 = "" } = {}): Building => {
  return {
    id: id,
    name: name,
    name2: name2,
    fullName: name + " " + name2
  };
};

const getOccurrenceType = ({ id = "", name = "" } = {}): OccurrenceType => {
  return {
    id: id,
    name: name
  };
};

const getTradeType = ({ id = "", name = "" } = {}): TradeType => {
  return {
    id: id,
    name: name
  };
};

const getErrorCauseType = ({ id = "", name = "" } = {}): ErrorCauseType => {
  return {
    id: id,
    name: name
  };
};
const getOccurrenceStatus = ({ id = "", name = "" } = {}): OccurrenceStatus => {
  return {
    id: id,
    name: name
  };
};

const getInstallation = ({
  id = "",
  ebene = "",
  location = "",
  description = "",
  name = ""
} = {}): Installation => {
  return {
    id: id,
    name: name,
    ebene: ebene,
    location: location,
    description: description
  };
};

const getResponsiblePerson = ({
  ID = "",
  LastName = "",
  FirstName = "",
  MobilePhoneNumber = "",
  Email = "",
  IsContactPerson = false
} = {}): ResponsiblePerson => {
  return {
    id: ID,
    lastName: LastName,
    firstName: FirstName,
    mobilePhoneNumber: MobilePhoneNumber,
    email: Email,
    isContactPerson: IsContactPerson
  };
};

export default {
  async getOccurrenceExcel(
    locale: string,
    params: {
      date_from?: string;
      date_to?: string;
      status?: string;
      realEstateId?: string;
      number?: string;
      realEstate?: string;
      buildingName?: string;
      occurrenceType?: string;
      date?: string;
      tradeType?: string;
      errorCause?: string;
      fetch_limit?: number;
    }
  ): Promise<any> {
    let response;
    let data;
    try {
      params.fetch_limit = 0;
      response = await Api.request().get(
        "/api/occurrences/GetReport/" + locale,
        {
          responseType: "blob",
          params
        }
      );
      data = Api.handleResponse(response);
      const occurrencesTranslation =
        (i18n.t("OCCURRENCE.TITLE") as string) || "Occurrences";
      saveAs(
        data,
        occurrencesTranslation + "_" + moment().format("YYYY_MM_DD") + ".xlsx"
      );
    } catch (err) {
      throw err;
    }
  },
  async getAllOccurrences(): Promise<Occurrence[]> {
    let response;
    let data;
    try {
      response = await Api.request().get("/api/occurrences/GetAll");
      data = Api.handleResponse(response);
    } catch (err) {
      throw err;
    }
    const occurrences: Occurrence[] = data.map((elem: any) => {
      // const building = getBuilding(elem.building);
      // const occurrenceType = getOccurrenceType(elem.occurrenceType);
      // const tradeType = getTradeType(elem.tradeType);
      // const errorCauseType = getErrorCauseType(elem.errorCauseType);
      // const occurrenceStatus = getOccurrenceStatus(elem.occurrenceStatus);
      // const installation = getInstallation(elem.installation);
      // const responsiblePerson = getResponsiblePerson(elem.responsiblePerson);
      // const occurrence: Occurrence = {
      //   id: elem.id,
      //   number: elem.number,
      //   realEstate: elem.realEstate,
      //   realEstateId: elem.realEstateId,
      //   building,
      //   date: new Date(elem.date),
      //   occurrenceType,
      //   tradeType,
      //   errorCauseType,
      //   occurrenceStatus,
      //   installation,
      //   description: elem.description || "",
      //   responsiblePerson,
      //   errorCauseDetails: elem.errorCauseDetails || "",
      //   buildingName: elem.buildingName,
      //   responsiblePersonName: elem.responsiblePersonName
      // };
      // console.log("elem.date", elem.date);
      // elem.dateNew = moment(elem.date);
      return elem;
    });
    return occurrences;
  },

  async addOccurrence(occurrenceData: any): Promise<void> {
    try {
      const response = await Api.request().post(
        "/api/occurrences/insert",
        occurrenceData
      );
      Api.handleResponse(response);
    } catch (err) {
      throw err;
    }
  }
};
