import { Api } from "@/services/api";
import { Document } from "@/types";

export default {
  async getAllDocuments(occurrenceId: string): Promise<Document[]> {
    const splittedId = occurrenceId.replace(":", "/");
    let response;
    let data;
    try {
      response = await Api.request().get("/api/documents/GetAll/" + splittedId);
      data = Api.handleResponse(response);
    } catch (err) {
      throw err;
    }
    const isHttpOrigin = location.protocol === "http:";
    const protocol = isHttpOrigin ? "http" : "https";
    const documents: Document[] = data.map((elem: any) => {
      const document: Document = {
        id: elem.id,
        creationDate: elem.creationDate,
        modificationDate: elem.modificationDate,
        path: `${protocol}://helpdesk.ics-fs.at${elem.path}`,
        type: elem.type
      };
      return document;
    });
    return documents;
  }
};
